<template>
<div class="signup-wrap mt-0">
    <div class="row">
        <div class="col-12 text-center mt-0">
            <img src="@/assets/img/logo.svg" class="mb-2" height="58px" alt="">
            <h3 class="title  pt-0 text-centerb">
                Sign Up </h3>

        </div>
    </div>

    <div class="row">
        <div class="col-lg-12 text-centerb ml-auto mr-auto col-md-12 pt-4">
            <ul class="errors px-0 text-left" v-for="error in validationErrors" :key="error.message">
                <li class="d-block text-danger px-0" v-if="validationErrors[0]!={}" data-aos="fade-in">
                     <ph-warning class="align-middle" />
                     <span class="ml-2 fnt-14 ls-default">{{ error.message }}</span>
                </li>
            </ul>
            <a :href="$baseurl +'/auth/google'" class="btn btn-secondary btn-block btn-lg d-none">
                <img class=" align-middle mx-2" height="16px" src="@/assets/img/google.svg"> Signup with Google
            </a>

            <div class="text-center d-none">
                <p class="description text-muted f-3" style="font-size:14px">
                    or Signup with Email
                </p>
            </div>

            <div class="label">
                <label class="d-block text-left label-text" for>Email Address</label>
            </div>
            <div class="form-group ">
                <input v-model="email" type="email" class="form-control form-c form-c-lg mb-2" />

                <small class="small-error text-danger form-text text-left" v-show="emailError != ''">
                    <i class="ph-warning align-middle"></i>
                    {{ emailError }}</small>
            </div>
            <div class="label">
                <label class="d-block text-left label-text" for>Password</label>
            </div>
            <div class="form-group ">
                <input v-model="password" type="password" class="form-control form-c form-c-lg mb-2" />
                <small class="small-error text-danger form-text text-left" v-show="passwordError != ''">
                    <i class="ph-warning  align-middle"></i>
                    {{ passwordError }}</small>
            </div>
            <div class="label">
                <label class="d-block text-left label-text" for>Confirm Password</label>
            </div>
            <div class="form-group">
                <input v-model="passwordRepeat" type="password" class="form-control form-c form-c-lg" />
                <small class="small-error text-danger form-text text-left" v-show="passwordRError != ''">
                    <i class="ph-warning  align-middle"></i>
                    {{ passwordRError }}</small>
            </div>
            <div class="tos mt-3 px-0 label text-left">
                <span class="mt-3 text-left">
                    By creating an account you agree to our
                    <a href="/terms" class="link">Terms & Conditions</a>
                </span>
            </div>
            <div class="send-button mt-3">
                <button href="#" @click.prevent="validate()" class="btn btn-primary btn-block btn-lg" :disabled="loading">Sign Up
                    <span v-show="loading" class="loading pl-1 my-auto pull-right text-right">
                    </span>
                </button>
            </div>
            <div class="col-12 text-centern px-0">
                <p class="description text-muted f-3" style="font-size:14px">
                    Already a User? <router-link to="/auth/login" class="pull-left f-5 link" style="font-size:16px">Login to Account</router-link>

                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
const email_re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
import {
    mapActions
} from "vuex";
import {
  PhWarning,

} from "phosphor-vue";
export default {
    components: {
        PhWarning,
    },
    data() {
        return {
            email: null,
            password: null,
            passwordRepeat: null,
            validationErrors: [],
            emailError: "",
            passwordError: "",
            passwordRError: "",
            loading: false,
        };
    },

    methods: {
        resetError() {
            this.validationErrors = [];
            this.emailError = "";
            this.passwordError = "";
            this.passwordRError = "";
        },
        validate() {
            this.resetError();
            this.loading = true
            if (!this.email) {
                this.emailError = "E-mail cannot be empty";
                this.loading = false
            }
            if (email_re.test(this.email) != true && this.email) {
                this.emailError = "Invalid Email Address format";
                this.loading = false
            }
            if (!this.password) {
                this.passwordError = "Password cannot be empty";
                this.loading = false
            }
            if (/.{6,}/.test(this.password) != true && this.password) {
                this.passwordError = "Password should be at least 6 characters long";
                this.loading = false
            }
            if (!this.passwordRepeat) {
                this.passwordRError = "Please confirm your password";
                this.loading = false
            }
            if (
                this.passwordRepeat != this.password &&
                this.password &&
                this.passwordRepeat
            ) {

                this.passwordRError = "Passwords do not match";
                this.loading = false
            }
            if (this.validationErrors.length <= 0 &&
                this.emailError === "" &&
                this.passwordError === "" &&
                this.passwordRError === "") {
                this.signUp();
            }
        },
        testv() {
            console.log("sign in", this.email, this.password);
        },
        ...mapActions(["setUpUserAction"]),
        signUpGoogle() {
            var provider = new firebase.auth.GoogleAuthProvider();
            firebase
                .auth()
                .signInWithPopup(provider)
                .then(response => {
                    console.log("Success! ", response);
                    var user = firebase.auth().currentUser;
                    this.setUpUserAction(user);
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorMessage = error.message;
                    this.validationErrors.push({
                        message: errorMessage
                    });
                });
        },
        signUp() {
            var baseURI = this.$baseurl + "/auth/register";

            this.$http.post(baseURI, {
                "email": this.email,
                "password": this.password
            }).then(result => {
                console.log(result.data)
                if (result.data.Token) {
                    this.resetError()
                    this.loading = false
                    this.setUpUserAction(result.data);

                } else {
                    this.validationErrors.push({
                        "message": result.data.message
                    })

                    this.loading = false
                }

            });
        }
    }
};
</script>

<style></style>
